
































import { updateLastReadPracticalInfo } from "@/api/customerService";
import { getPracticalInfo } from "@/api/platformSettingsService";
import useAsyncData from "@/hooks/useAsyncData";
import { uiModule } from "@/store/modules/ui";
import { defineComponent, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "PracticalInfo",
  setup() {
    const {
      data: practicalInfo,
      isLoading,
    } = useAsyncData(() => getPracticalInfo());

    onMounted(async () => {
      await updateLastReadPracticalInfo();
      uiModule.actions.fetchUnreadPracticalInfoCount();
    });

    return {
      practicalInfo,
      isLoading,
    };
  },
});
